<template>
  <div>
    <v-card
      v-if="mostRecentProjectStatus(projects) === 'On Track'"
      color="var(--green)"
      class="rounded-lg white--text"
      >On Track</v-card
    >
    <v-card
      v-else-if="mostRecentProjectStatus(projects) === 'Off Track'"
      color="var(--red)"
      class="rounded-lg white--text"
      >Off Track</v-card
    >
    <v-card
      v-else-if="mostRecentProjectStatus(projects) === 'At Risk'"
      color="var(--yellow)"
      class="rounded-lg"
      >At Risk</v-card
    >
    <v-card
      v-else-if="mostRecentProjectStatus(projects) === 'Completed'"
      color="#3f3f3f"
      class="rounded-lg white--text"
      >Completed</v-card
    >
    <v-card
      v-else-if="mostRecentProjectStatus(projects) === 'Cancelled'"
      color="#b8b7b7"
      class="rounded-lg"
      >Cancelled</v-card
    >
    <span v-else>-</span>
  </div>
</template>
<script>
export default {
  name: "StatusChip",
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
  components: {},
  methods: {
    mostRecentProjectStatus(projects) {
      let status = "On Track";
      let updates =
        projects.length && projects.filter((p) => p.last_update != null);
      if (updates.length > 1) {
        status = projects.reduce((a, b) =>
          a.last_update && a.last_update > b.last_update ? a : b
        )["status"][1];
      } else if (updates.length) {
        status = updates[0]["status"][1];
      }
      return status;
    },
  },
};
</script>
